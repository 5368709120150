import request from "@/Rest";
import { SURVEY_URLS } from "@/Rest/api/urls";

export async function getQuestions(id) {
  try {
    const { data } = await request.GET(SURVEY_URLS.getQuestions(id));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function setUserAnswers(answers) {
  const payload = answers
  try {

    const { data } = await request.POST(SURVEY_URLS.setUserAnswers, payload);

    return { data, success: true };
  } catch (error) {
    const data = error.response.data
    return { data, success: false };
  }
}
