<template>
  <div class="flex flex-col space-y-6 space-y-4 bg-white h-auto w-full p-8">
    <div v-for="(question, qIndex) in questions" :key="question.id" class="flex">
      <div class="text-2xl font-semibold text-gray-800 w-8 flex-shrink-0">
        {{ qIndex + 1 }}
      </div>
      <div class="flex flex-col flex-grow ml-4">
        <div class="text-lg text-gray-700">
          {{ question.text }}
        </div>
        <div class="mt-2">
          <div v-for="option in question.response_options" :key="option.id" class="flex items-center mt-2">
            <input type="radio" :name="`question-${qIndex}`" :value="option.id" v-model="userResponses[question.id]"
              class="form-radio text-indigo-600">
            <label for="option-{{option.id}}" class="ml-2 text-gray-600">
              {{ option.text }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <button id="b-send" @click="submitAnswers" :disabled="proccessingSurvey || questions.length === 0"
      class="text-darkBlue bg-mindaro font-semibold mt-8 p-3 border-0 mx-3">
      <em v-if="proccessingSurvey" class="fa fa-spinner fa-spin"></em>
      Submit answers
    </button>
  </div>
</template>


<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import { useToast } from "vue-toastification";
import { getQuestions, setUserAnswers } from "@/services/survey/survey.js";

const toast = useToast();
const route = useRoute();
const router = useRouter();

const questions = ref([]);
const userResponses = ref({});
const userSurveyId = ref("")
const proccessingSurvey = ref(false)

const ERROR_MESSAGES = {
  surveyAnswered: "The survey was already answered",
  defaultError: "There was an error sending the answers."
};

async function submitAnswers() {
  const allAnswered = questions.value.every(question => userResponses.value.hasOwnProperty(question.id));
  if (!allAnswered) {
    toast.warning("Please answer all questions before sending.")
    return;
  }
  proccessingSurvey.value = true

  const answers = questions.value.map(question => ({
    user_survey: userSurveyId.value,
    question: question.id,
    response_option: userResponses.value[question.id]
  }));

  const response = await setUserAnswers(answers)

  if (!response.success) {
    proccessingSurvey.value = false
    const message = response.data === ERROR_MESSAGES.surveyAnswered
      ? ERROR_MESSAGES.surveyAnswered
      : ERROR_MESSAGES.defaultError;
    toast.error(message)
  } else {
    proccessingSurvey.value = false
    return router.push({ name: "Home" });
  }
};

onMounted(async () => {
  try {
    const { data } = route.query;
    userSurveyId.value = data
    const response = await getQuestions(data);
    questions.value = response.data?.questions;
  } catch (error) {
    toast.error("There was an error loading the answers.")
  }
});

</script>

<style lang="scss">
#b-send:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15em solid #1d2e48;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  transform: scale(0);
  border: 0.4em solid #1d2e48;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em red;

  +.contendero {
    border-color: green;
  }
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
</style>
